<template>
  <v-container fluid>
    <h1
      v-translate
      translate-context="Page title"
    >
      Dashboard
    </h1>

    <div
      class="masonry-container pt-3"
    >
      <v-card
        class="masonry-col"
      >
        <v-card-title v-translate>
          Overall status
        </v-card-title>

        <v-card-text
          class="d-flex justify-space-around align-center pt-4"
        >
          <v-progress-circular
            :value="progressInPercentage"
            size="200"
            width="8"
            rotate="-90"
            color="primary"
          >
            <div
              class="text-center d-flex flex-column"
            >
              <span
                class="display-2"
              >
                {{ progressInPercentage }}%
              </span>

              <span
                v-translate
                translate-context="Progress indicator"
                class="d-block"
              >
                complete
              </span>
            </div>
          </v-progress-circular>

          <div>
            <div
              class="display-1 font-weight-bold primary--text"
            >
              {{ projectProgressTotal.todosCompletedCount }}
              /
              {{ projectProgressTotal.todosTotalCount }}
            </div>

            <div
              class="d-flex align-center justify-center mt-2"
            >
              <div
                style="border-radius: 100%; width: 12px; height: 12px;"
                class="primary"
              />

              <span
                v-translate
                class="ml-2 caption"
              >
                Tasks complete
              </span>
            </div>
          </div>
        </v-card-text>

        <v-divider
          class="my-4"
        />

        <v-card-text>
          <v-slider
            v-if="currentTact.taktIndex"
            :value="currentTact.taktIndex"
            :max="maxSliderValue"
            thumb-label="always"
            :thumb-size="48"
            readonly
            color="primary"
            hide-details
            track-color="primary lighten-4"
            class="mt-2 mb-4 mx-4"
          >
            <template v-slot:thumb-label="props">
              <div
                class="font-weight-bold text-center"
                style="line-height: 14px; margin-bottom: -4px;"
              >
                <span v-translate>
                  Takt
                </span>
                <br>
                {{ props.value }}
              </div>
            </template>

            <template #append>
              <div
                class="caption font-weight-bold"
                style="white-space: nowrap"
              >
                {{ sliderLabel }}
              </div>
            </template>
          </v-slider>

          <div
            class="d-flex flex-wrap pa-4"
          >
            <div>
              <span
                v-translate
                class="text-uppercase font-weight-bold d-block caption"
              >
                Project ends on
              </span>
              <span
                class="display-1 font-weight-bold primary--text"
              >
                {{ projectEndsAt }}
              </span>
            </div>

            <v-divider
              vertical
              class="mx-10"
            />

            <div>
              <span
                v-translate
                class="text-uppercase font-weight-bold d-block caption"
              >
                Takt time period
              </span>
              <span
                class="display-1 primary--text font-weight-bold"
              >
                {{ renderTactDuration }}
              </span>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <v-card
        class="masonry-col"
      >
        <v-card-title>
          <span v-translate>
            Task fulfillment
          </span>

          <!-- Funky template layout required for proper word spacing -->
          <m-tooltip
            bottom
            icon-class="ml-2"
          >
            <v-card-text>
              <span
                v-translate
                translate-context="Task fulfillment tooltip [1/5]"
              >
                Tasks marked as complete after the project has ended are not
                shown in this graph. Due to this,
              </span>
              <span
                v-translate
                translate-context="Task fulfillment tooltip [2/5]"
                style="background-color: rgba(37, 179, 162, 0.5);"
                class="caption pa-1 font-weight-bold white--text"
              >Planned</span>
              <span
                v-translate
                translate-context="Task fulfillment tooltip [3/5]"
                style="display: inline-block; padding: 0 2px;"
              >
                and
              </span>
              <span
                v-translate
                translate-context="Task fulfillment tooltip [4/5]"
                style="background-color: rgba(255, 193, 7, 0.25);"
                class="caption pa-1 font-weight-bold white--text"
              >Complete</span>
              <span
                v-translate
                translate-context="Task fulfillment tooltip [5/5]"
              >
                may not match each other at the end of the project.
              </span>
            </v-card-text>
          </m-tooltip>
        </v-card-title>

        <v-card-subtitle v-translate>
          Finished tasks at the end of last takt
        </v-card-subtitle>

        <v-card-text
          class="pt-4"
        >
          <m-loader-circular
            v-if="$wait.is('loading due todos progress')"
            column
            justify-center
            align-center
            class="pa-12 ma-12"
            color="error"
            text=""
            :size="24"
            :width="2"
          />

          <div
            v-else-if="
              datasets.cumulativePlanned.length <= 0
                && datasets.cumulativeCompleted.length <= 0
            "
            v-translate
            class="text-center font-italic my-12"
          >
            No data
          </div>

          <canvas
            v-show="
              !$wait.is('loading due todos progress')
                && datasets.cumulativePlanned.length > 0
                && datasets.cumulativeCompleted.length > 0
            "
            ref="cumulativeTotalCanvas"
          />
        </v-card-text>
      </v-card>

      <v-card
        class="masonry-col"
      >
        <v-card-title>
          <span v-translate>
            Overdue tasks
          </span>

          <m-tooltip
            bottom
            icon-class="ml-2"
            max-width="400px"
          >
            <v-card-text
              class="pb-6"
            >
              <div
                v-translate
                translate-context="Overdue tasks tooltip title section 1"
                class="title font-weight-bold text-uppercase mb-2"
              >
                What does this indicate?
              </div>

              <span
                v-translate
                translate-context="Overdue tasks tooltip section 1 [1/5]"
              >
                This graph shows how many tasks are overdue after each takt.
              </span>

              <br><br>

              <span
                v-translate
                translate-context="Overdue tasks tooltip section 1 [2/5]"
              >
                In practice, this graph visualizes the calculation
              </span>

              <br>

              <span
                v-translate
                translate-context="Overdue tasks tooltip section 1 [3/5]"
                style="color: rgba(37, 179, 162, 0.5); font-style: italic;"
              >
                completed tasks - planned tasks = overdue tasks
              </span>

              <br>

              <span
                v-translate
                translate-context="Overdue tasks tooltip section 1 [4/5]"
              >
                at the end of each takt.
              </span>

              <br><br>

              <span
                v-translate
                translate-context="Overdue tasks tooltip section 1 [5/5]"
              >
                The graph is cumulative; it displays the total sum of all overdue tasks.
              </span>

              <v-divider
                class="my-5"
              />

              <div
                v-translate
                translate-context="Overdue tasks tooltip title section 2"
                class="title font-weight-bold text-uppercase mb-2"
              >
                What are overdue tasks?
              </div>

              <span
                v-translate
                translate-context="Overdue tasks tooltip section 2 [1/3]"
              >
                Overdue tasks are tasks that have not been completed during the planned
                takt (e.g. day).
              </span>

              <div
                class="mt-4"
              >
                <span
                  v-translate
                  translate-context="Overdue tasks tooltip section 2 [2/3]"
                >
                  A task can be overdue if its status is:
                </span>

                <div
                  class="mt-2"
                >
                  <span
                    v-translate
                    translate-context="Task status"
                    class="caption py-1 px-2 font-weight-bold white--text m-state-enabled"
                  >
                    Can begin
                  </span>

                  <span
                    v-translate
                    translate-context="Task status"
                    class="caption py-1 px-2 font-weight-bold white--text m-state-started mx-2"
                  >
                    Started
                  </span>

                  <span
                    v-translate
                    translate-context="Task status"
                    class="caption py-1 px-2 font-weight-bold white--text m-state-interrupted"
                  >
                    Interrupted
                  </span>
                </div>
              </div>

              <div
                class="mt-6"
              >
                <span
                  v-translate
                  translate-context="Overdue tasks tooltip section 2 [3/3]"
                >
                  A task is not overdue if its status is:
                </span>

                <div
                  class="mt-2"
                >
                  <span
                    v-translate
                    translate-context="Task status"
                    class="caption py-1 px-2 font-weight-bold primary--text m-state-completed mr-2"
                  >
                    Complete
                  </span>

                  <span
                    v-translate
                    translate-context="Task status"
                    class="caption py-1 px-2 font-weight-bold primary--text m-state-skipped"
                  >
                    Skipped
                  </span>
                </div>
              </div>

              <v-alert
                type="warning"
                dense
                text
                class="caption mt-8"
                border="left"
              >
                <span
                  v-translate
                  translate-context="Overdue tasks tooltip section 3"
                >
                  Tasks marked as complete after the project has ended are not shown in this graph.
                  This means that the amount of overdue tasks may not be zero at the end of the
                  project.
                </span>
              </v-alert>
            </v-card-text>
          </m-tooltip>
        </v-card-title>

        <v-card-subtitle v-translate>
          Overdue tasks at the end of last takt
        </v-card-subtitle>

        <v-card-text
          class="pt-4"
        >
          <m-loader-circular
            v-if="$wait.is('loading due todos progress')"
            column
            justify-center
            align-center
            class="pa-12 ma-12"
            color="error"
            text=""
            :size="24"
            :width="2"
          />

          <div
            v-else-if="datasets.cumulativeDues.length <= 0"
            v-translate
            class="text-center font-italic my-12"
          >
            No data
          </div>

          <canvas
            v-show="
              !$wait.is('loading due todos progress')
                && datasets.cumulativeDues.length > 0
            "
            ref="cumulativeDuesCanvas"
          />
        </v-card-text>

        <v-divider
          class="mt-4"
        />

        <v-card-text
          class="text-center pa-6"
        >
          <div
            class="display-1 error--text font-weight-bold"
          >
            {{ duesTotal }}
          </div>

          <div
            class="d-flex align-center justify-center mt-2"
          >
            <div
              style="border-radius: 100%; width: 12px; height: 12px;"
              class="error"
            />

            <span
              v-translate
              translate-context="Overdue chart legend"
              class="ml-2 caption"
            >
              Total tasks overdue
            </span>
          </div>
        </v-card-text>
      </v-card>

      <v-card
        class="masonry-col"
      >
        <v-card-title>
          <span v-translate>
            Interruptions
          </span>

          <m-tooltip
            bottom
            icon-class="ml-2"
          >
            <v-card-text>
              <span
                v-translate
                translate-context="Interruptions tooltip [1/3]"
              >
                Tasks marked as complete after the project has ended are not
                shown in this graph. Because of this,
              </span>
              <span
                v-translate
                translate-context="Interruptions tooltip [2/3]"
                style="background-color: rgba(251, 140, 0, 0.25); white-space: nowrap;"
                class="caption pa-1 font-weight-bold white--text"
              >Accumulated interruptions</span>
              <span
                v-translate
                translate-context="Interruptions tooltip [3/3]"
              >
                may not be zero at the end of the project.
              </span>
            </v-card-text>
          </m-tooltip>
        </v-card-title>

        <v-card-subtitle v-translate>
          Interruptions at the end of last takt
        </v-card-subtitle>

        <v-card-text
          class="pt-4 relative"
        >
          <div>
            <m-loader-circular
              v-if="$wait.is('loading interruptions progress')"
              column
              justify-center
              align-center
              class="pa-12 ma-12"
              color="warning"
              text=""
              :size="24"
              :width="2"
            />

            <div
              v-else-if="datasets.cumulativeInterruptions.length <= 0"
              v-translate
              class="text-center font-italic my-12"
            >
              No data
            </div>

            <canvas
              v-show="
                !$wait.is('loading interruptions progress')
                  && datasets.cumulativeInterruptions.length > 0
              "
              ref="interruptionsCanvas"
            />
          </div>
        </v-card-text>

        <!--            class="mt-4"-->
        <v-divider />

        <v-card-text
          class="pa-6"
        >
          <div
            class="d-flex flex-wrap"
          >
            <div
              class="d-flex justify-space-around flex-column"
            >
              <div
                class="px-6 py-4"
              >
                <div
                  class="display-1 warning--text text--darken-1 font-weight-bold"
                >
                  {{ projectInterruptions.activeInterruptions }}
                </div>

                <div
                  class="d-flex align-center justify-center mt-2"
                >
                  <div
                    style="border-radius: 100%; width: 12px; height: 12px;"
                    class="warning text--darken-1"
                  />

                  <span
                    v-translate
                    translate-context="Interruptions chart legend"
                    class="ml-2 caption"
                  >
                    Active interruptions
                  </span>
                </div>
              </div>

              <div
                class="px-6 py-4"
              >
                <div
                  class="display-1 warning--text text--lighten-1 font-weight-bold"
                >
                  {{ projectInterruptions.interruptionsTotal }}
                </div>

                <div
                  class="d-flex align-center justify-center mt-2"
                >
                  <div
                    style="border-radius: 100%; width: 12px; height: 12px;"
                    class="warning lighten-1"
                  />

                  <span
                    v-translate
                    translate-context="Interruptions chart legend"
                    class="ml-2 caption"
                  >
                    Total interruptions
                  </span>
                </div>
              </div>
            </div>

            <v-divider
              vertical
              class="mx-4"
            />

            <div>
              <v-list
                dense
              >
                <v-subheader v-translate>
                  Most common reasons
                </v-subheader>

                <v-list-item
                  v-for="(interruption, i) in projectInterruptions.mostCommonInterruptions"
                  :key="i"
                >
                  <v-list-item-content>
                    <!-- i18n TODO -->
                    <v-list-item-title>
                      {{ i + 1 }}.
                      {{ interruption.humanReadableReason }} ({{ interruption.count }} kpl)
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <v-card
        class="masonry-col"
      >
        <v-card-title v-translate>
          Progress per subcontractor
        </v-card-title>

        <v-card-subtitle v-translate>
          Task fulfillment team-by-team
        </v-card-subtitle>

        <v-simple-table
          class="elevation-2"
          :style="{
            maxHeight: '600px',
            overflowY: 'scroll',
          }"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th v-translate>
                  Team
                </th>
                <th v-translate>
                  Complete / Total
                </th>
                <th v-translate>
                  Complete %
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(team, i) in todosLeftByTeam"
                :key="i"
              >
                <td>
                  {{ team.teamName }}
                </td>

                <td>
                  {{ Math.round(team.cumulativeCompletedHours * 10) / 10 }} h
                  /
                  {{ Math.round(team.cumulativeTotalHours * 10) / 10 }} h
                </td>

                <td>
                  {{ toPercentage(team.cumulativeCompletedHours, team.cumulativeTotalHours) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import Chart from 'chart.js/auto';

  export default {
    data: () => ({
      interruptionsCanvasInitialized: false,
      cumulativeDuesCanvasInitialized: false,
      cumulativeTotalCanvasInitialized: false,
    }),

    computed: {
      ...mapGetters({
        projectProgressTotal: 'project/dashboard/projectProgressTotal',
        projectInterruptions: 'project/dashboard/projectInterruptions',
        dueTodosProgress: 'project/dashboard/dueTodosProgress',
        todosLeftByTeam: 'project/dashboard/todosLeftByTeam',
        currentTact: 'project/tacts/currentTact',
        tacts: 'project/tacts/tacts',
        project: 'project/project/project',
      }),

      projectEndsAt() {
        if (!this.lastTact) return '';
        const endsAt = this.lastTact.workdaysEndsAt;
        return this.$dayjs(endsAt).format('DD.MM.YYYY');
      },

      renderTactDuration() {
        const { tactDuration } = this.project;
        return this.$gettextInterpolate(
          this.$ngettext('%{ tactDuration } day', '%{ tactDuration } days', tactDuration),
          { tactDuration },
        );
      },

      lastTact() {
        if (this.tacts.length <= 0) return null;

        return this.tacts[this.tacts.length - 1];
      },

      maxSliderValue() {
        if (!this.lastTact) return 0;

        return this.lastTact.taktIndex;
      },

      sliderLabel() {
        if (!this.lastTact) return 0;

        return `${this.$gettext('Takt')} ${this.lastTact.taktIndex}`;
      },

      progressInPercentage() {
        const {
          todosTotalCount,
          todosCompletedCount,
        } = this.projectProgressTotal;

        if (todosTotalCount <= 0) return 0;

        return Math.round((todosCompletedCount / todosTotalCount) * 100);
      },

      datasets() {
        const datasets = this.dueTodosProgress.reduce((acc, item) => {
          acc.interruptionLabels.push(item.takt);
          acc.cumulativeInterruptions.push(item.cumulativeInterruptionsCount);
          if (item.takt < this.currentTact.taktIndex) {
            acc.dueLabels.push(item.takt);
            acc.cumulativeDues.push(item.cumulativeDuesCount);

            acc.cumulativeTotalLabels.push(item.takt);
            acc.cumulativePlanned.push(item.cumulativePlannedCount);
            acc.cumulativeCompleted.push(item.cumulativeCompletedCount);
          }

          return acc;
        }, {
          interruptionLabels: [],
          cumulativeInterruptions: [],

          dueLabels: [],
          cumulativeDues: [],

          cumulativeTotalLabels: [],
          cumulativePlanned: [],
          cumulativeCompleted: [],
        });

        return datasets;
      },

      cumulativePlannedDataset() {
        return {
          label: this.$pgettext('Chart data label', 'Planned'),
          data: this.datasets.cumulativePlanned,
          borderColor: 'rgba(37, 179, 162, 0.5)',
          fill: false,
        };
      },

      cumulativeCompletedDataset() {
        return {
          label: this.$pgettext('Chart data label', 'Complete'),
          data: this.datasets.cumulativeCompleted,
          borderColor: 'rgba(255, 193, 7, 0.5)',
          backgroundColor: 'rgba(255, 193, 7, 0.25)',
        };
      },

      cumulativeDuesDataset() {
        return {
          label: this.$pgettext('Chart data label', 'Accumulated overdue tasks'),
          data: this.datasets.cumulativeDues,
          borderColor: 'rgba(247, 104, 105, 0.5)',
          backgroundColor: 'rgba(247, 104, 105, 0.25)',
        };
      },

      interruptionsDataset() {
        return {
          label: this.$pgettext('Chart data label', 'Accumulated interruptions'),
          data: this.datasets.cumulativeInterruptions,
          borderColor: 'rgba(251, 140, 0, 0.5)',
          backgroundColor: 'rgba(251, 140, 0, 0.25)',
        };
      },

      duesTotal() {
        const lastIndex = this.datasets.cumulativeDues.length - 1;
        if (lastIndex < 0) return 0;
        return this.datasets.cumulativeDues[lastIndex];
      },

      chartOptions() {
        return {
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              // May need 'pcs/kpl' suffix
              callbacks: {
                title: a => `T${parseInt(a[0].label, 10)}`,
                label: ({ dataset, formattedValue }) => `${dataset.label}: ${formattedValue}`,
              },
            },
          },
          scales: {
            x: {
              ticks: {
                maxTicksLimit: 5,
                callback: (v) => {
                  if (v <= 0) return '';
                  // Takt zero is displayed as takt 1
                  return `T${v+1}`;
                },
              },
            },
            y: {
              beginAtZero: true,
              ticks: {
                maxTicksLimit: 5,
                // May need 'pcs/kpl' suffix
                callback: (v) => {
                  if (v <= 0) return '';
                  return `${v}`;
                },
              },
            },
          },
        };
      },
    },

    async created() {
      this.$wait.start('loading due todos progress');
      this.$wait.start('loading interruptions progress');

      await Promise.all([
        this.loadCurrentTact(),
        this.loadTacts(),
        this.loadProjectProgressTotal(),
        this.loadProjectInterruptions(),
        this.loadDueTodosProgress(),
        this.loadTodosLeftByTeam(),
      ]);

      this.$wait.end('loading due todos progress');
      this.$wait.end('loading interruptions progress');

      this.$nextTick(() => {
        this.initInterruptionsChart();
        this.initCumulativeDuesChart();
        this.initCumulativeTotalChart();
      });
    },

    methods: {
      ...mapActions({
        loadProjectProgressTotal: 'project/dashboard/loadProjectProgressTotal',
        loadTodosLeftByTeam: 'project/dashboard/loadTodosLeftByTeam',
        loadCurrentTact: 'project/tacts/loadCurrentTact',
        loadTacts: 'project/tacts/loadTacts',
        loadDueTodosProgress: 'project/dashboard/loadDueTodosProgress',
        loadProjectInterruptions: 'project/dashboard/loadProjectInterruptions',
      }),

      initInterruptionsChart() {
        if (this.interruptionsCanvasInitialized) return;
        if (this.datasets.cumulativeInterruptions.length <= 0) return;

        new Chart(this.$refs.interruptionsCanvas, { // eslint-disable-line
          type: 'line',
          data: {
            labels: this.datasets.interruptionLabels,
            datasets: [
              this.interruptionsDataset,
            ],
          },
          options: this.chartOptions,
        });

        this.interruptionsCanvasInitialized = true;
      },

      initCumulativeDuesChart() {
        if (this.cumulativeDuesCanvasInitialized) return;
        if (this.datasets.cumulativeDues.length <= 0) return;

        new Chart(this.$refs.cumulativeDuesCanvas, { // eslint-disable-line
          type: 'line',
          data: {
            labels: this.datasets.dueLabels,
            datasets: [
              this.cumulativeDuesDataset,
            ],
          },
          options: this.chartOptions,
        });

        this.cumulativeDuesCanvasInitialized = true;
      },

      initCumulativeTotalChart() {
        if (this.cumulativeTotalCanvasInitialized) return;
        if (this.datasets.cumulativePlanned.length <= 0
          && this.datasets.cumulativeCompleted.length <= 0) return;

        new Chart(this.$refs.cumulativeTotalCanvas, { // eslint-disable-line
          type: 'line',
          data: {
            labels: this.datasets.cumulativeTotalLabels,
            datasets: [
              this.cumulativePlannedDataset,
              this.cumulativeCompletedDataset,
            ],
          },
          options: this.chartOptions,
        });

        this.cumulativeTotalCanvasInitialized = true;
      },

      toPercentage(x, y) {
        if (y === 0) return '-';

        const fraction = (x / y) * 100;

        return `${Math.round(fraction * 10) / 10} %`;
      },
    },
  };
</script>

<style lang="scss" scoped>
.masonry-container {
  columns: 3 500px;
  column-gap: 16px;

  .masonry-col {
    position: unset;
    margin: 0 16px 16px 0;
    display: inline-block;
    width: 100%;
  }
}
</style>
